import useFetchApi from '@helpers/useFetchApi';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

interface UpdateUserResponse {
  email: string;
  name: string;
  timezone: string;
  type: string;
}

interface UpdatedUser {
  name: string;
  timezone: string;
}

export default function useUpdateUser(): UseMutationResult<
  UpdateUserResponse,
  unknown,
  UpdatedUser
> {
  const fetchApi = useFetchApi();

  const queryClient = useQueryClient();

  return useMutation(updatedUser => fetchApi.post('/api/user/', updatedUser), {
    onSuccess: newUser => {
      queryClient.setQueryData('user', newUser);
    },
  });
}
