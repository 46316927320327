import PageMeta from '@components/PageMeta';
import useTranslation from '@helpers/useTranslation';
import DashboardTemplate from '@templates/DashboardTemplate';
import DashboardProfileView from '@views/DashboardProfileView';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

const DashboardProfilePage: FC<PageProps> = () => {
  const { t } = useTranslation('pages');

  return (
    <DashboardTemplate>
      <PageMeta title={t('profile')} />
      <DashboardProfileView />
    </DashboardTemplate>
  );
};

export default DashboardProfilePage;
