import useFetchApi from '@helpers/useFetchApi';
import { useQuery } from 'react-query';

interface FetchUserResponse {
  email: string;
  name: string;
  timezone: string;
  type: string;
}

export default function useFetchUser(): SuspendedQueryResult<FetchUserResponse> {
  const fetchApi = useFetchApi();

  return useQuery('user', () =>
    fetchApi.get('/api/user/')
  ) as SuspendedQueryResult<FetchUserResponse>;
}
