import useFetchUser from '@api/useFetchUser';
import useUpdateUser from '@api/useUpdateUser';
import SelectField from '@components/SelectField';
import ServerErrorMessage from '@components/ServerErrorMessage';
import TextField from '@components/TextField';
import { useCreateToast } from '@context/ToastContext';
import useTranslation from '@helpers/useTranslation';
import React, { FC } from 'react';
import { Box, Button, Columns, Form, Heading } from 'react-bulma-components';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import timezones from './timezones.json';

interface FormValues {
  email: string;
  name: string;
  timezone: string;
}

const DashboardProfileView: FC = () => {
  const { t } = useTranslation(['profile', 'validation', 'pages']);

  const createToast = useCreateToast();

  const { data: user } = useFetchUser();
  const {
    isError: hasUpdateUserError,
    isLoading: isUpdatingUser,
    mutate: updateUser,
  } = useUpdateUser();

  const formProps = useForm<FormValues>({
    defaultValues: {
      email: user.email,
      name: user.name,
      timezone: user.timezone,
    },
  });

  const handleFormSubmit: SubmitHandler<FormValues> = ({ name, timezone }) => {
    updateUser(
      { name, timezone },
      {
        onSuccess: () => {
          createToast(t('profileUpdateSuccess'), 'success');
        },
      }
    );
  };

  return (
    <Box>
      <Heading>{t('pages:profile')}</Heading>
      <Columns>
        <Columns.Column size={10} widescreen={{ size: 8 }}>
          <FormProvider {...formProps}>
            <form onSubmit={formProps.handleSubmit(handleFormSubmit)}>
              <TextField<FormValues>
                id="name"
                label={t('common:name')}
                name="name"
                validation={{ required: t('validation:required', { field: t('common:name') }) }}
              />
              <TextField<FormValues> disabled id="email" label={t('common:email')} name="email" />
              <SelectField<FormValues> id="timezone" label={t('timezone')} name="timezone">
                {timezones.map(timezone => (
                  <option key={timezone.value} value={timezone.value}>
                    {timezone.label}
                  </option>
                ))}
              </SelectField>
              {hasUpdateUserError && <ServerErrorMessage />}
              <Form.Field>
                <Button color="primary" loading={isUpdatingUser}>
                  {t('common:update')}
                </Button>
              </Form.Field>
            </form>
          </FormProvider>
        </Columns.Column>
      </Columns>
    </Box>
  );
};

export default DashboardProfileView;
