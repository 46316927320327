import cc from 'classcat';
import React, { ReactElement, ReactNode, SelectHTMLAttributes } from 'react';
import { Form } from 'react-bulma-components';
import { Path, RegisterOptions, useFormContext } from 'react-hook-form';

interface SelectFieldProps<TFormValues extends FormValues>
  extends SelectHTMLAttributes<HTMLSelectElement> {
  Caption?: ReactNode;
  id: string;
  label: string;
  name: Path<TFormValues>;
  validation?: RegisterOptions<TFormValues>;
}

const SelectField = <TFormValues extends FormValues = FormValues>({
  Caption,
  children,
  className,
  id,
  label,
  name,
  validation = {},
  ...remainingProps
}: SelectFieldProps<TFormValues>): ReactElement => {
  const formContext = useFormContext<TFormValues>();

  const {
    formState: { errors },
    register,
  } = formContext;

  const error = errors[name];
  const errorMessage = Array.isArray(error) ? error?.[0].message : error?.message;

  return (
    <Form.Field>
      <Form.Label htmlFor={id}>{label}</Form.Label>
      <div
        className={cc({
          select: true,
          'is-fullwidth': true,
          'is-danger': !!error,
        })}
      >
        <select className={className} id={id} {...remainingProps} {...register(name, validation)}>
          {children}
        </select>
      </div>
      {errorMessage && <Form.Help color="danger">{errorMessage}</Form.Help>}
      {Caption && <Form.Help>{Caption}</Form.Help>}
    </Form.Field>
  );
};

export default SelectField;
